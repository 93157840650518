import { useState, useEffect, useMemo } from "react";
import { starColors, stars } from "./StarConfig";
import "./Stars.css";

function Stars(props) {
    const [starData, setStarData] = useState([]);
    const [isMoving, setIsMoving] = useState(true);

    const generatedStars = useMemo(() => generateStars(50), []);

    useEffect(() => {
        setStarData(generatedStars);
    }, [generatedStars]);

    useEffect(() => {
        setIsMoving(props.isMoving);
    }, [props.isMoving]);

    return (
        <div className={`stars ${isMoving ? "moving" : ""}`}>
            {props.children}
            {starData.map((star, index) => {
                const style = {
                    left: star.left,
                    top: star.top,
                    animationDuration: `${star.speed}s`,
                    animationDelay: `${star.delay}s`,
                    color: star.color,
                };
                return (
                    <div key={index} style={style} className="star">
                        {star.character}
                    </div>
                );
            })}
        </div>
    );
}

export default Stars;

function getRandomNumber() {
    return Math.floor(Math.random() * 100) + 1;
}

function generateStars(n) {
    return Array.from({ length: n }, () => {
        const speed = Math.floor(Math.random() * 5) + 5; // Speed between 5-10 seconds
        const delay = Math.random() * 5; // Random delay between 0-5 seconds
        const left = "110%"; // Start off-screen on the right
        const color = starColors[Math.floor(Math.random() * starColors.length)];
        return {
            character: stars[Math.floor(Math.random() * stars.length)],
            left,
            top: `${getRandomNumber()}%`,
            speed,
            delay,
            color,
        };
    });
}
